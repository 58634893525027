import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Autocomplete, Breadcrumbs, Checkbox, Chip, FormControlLabel, MenuItem, TextField, Typography } from '@mui/material';
import Link from '../Link';
import SaveButton from '../SaveButton';
import { domain } from '../../App';
import db from '../../scripts/db';
import { breadcrumbStyles } from '../../scripts/styles';

const activityTypes = ['Psychoeducation', 'Exposure', 'Assessment', 'Mindfulness/Relaxation', 'Reinforcers'];
const textFieldStyles = {
    width: '100%',
    maxWidth: '600px',
    margin: '0 0 16px 0'
};

const GET_ACTIVITY = gql`
    query GetActivity($id: UUID!) {
        ocgTActivityById(id: $id) {
            id
            name
            description
            activityType
            imageFileName
            published
            searchTerms
            clinicianId
            ocgTClinicianByClinicianId { name email }
            ocgTTagsByActivityId(orderBy: NAME_ASC) { nodes { id name }}
        }
    }
`;
const CREATE_TAG = gql`
    mutation CreateTag($activityId: UUID!, $name: String!) {
        createOcgTTag(input: {ocgTTag: {activityId: $activityId, name: $name}}) {
            ocgTTag { id }
        }
    }
`;
const DELETE_TAG = gql`
    mutation DeleteTag($id: UUID!) {
        deleteOcgTTagById(input: {id: $id}) {
            ocgTTag { id }
        }
    }
`;

export default function ActivityForm() {
    const navigate = useNavigate();
    const params = useParams();
    const [id, setId] = React.useState(null);
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [activityType, setActivityType] = React.useState(0);
    const [searchTerms, setSearchTerms] = React.useState([]);
    const [originalTags, setOriginalTags] = React.useState([]);
    const [tagsInputValue, setTagsInputValue] = React.useState('');
    const [tags, setTags] = React.useState([]);
    const [image, setImage] = React.useState('');
    const [formError, setFormError] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const getActivity = useQuery(GET_ACTIVITY, {
        variables: { id: params.id },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    const [createTag] = useMutation(CREATE_TAG);
    const [deleteTag] = useMutation(DELETE_TAG);
    const activity = getActivity?.data?.ocgTActivityById;
    const getActivityError = getActivity?.error;

    function toggleNameInSearchTerms(e, checked) {
        const nameToToggle = activity?.ocgTClinicianByClinicianId?.name;
        if (checked === true) {
            setSearchTerms(nameToToggle);
        } else {
            setSearchTerms('');
        }
    }
    async function saveTags(activityId) {
        const tagsToDelete = [];
        const tagsToCreate = [];

        // if there is text in the input that wasn't confirmed by the user, add it to the tags to create
        if (tagsInputValue) {
            tagsToCreate.push(tagsInputValue);
        }
    
        tags.forEach(tag => {
            if (!tag?.id) {
                tagsToCreate.push(tag);
            }
        });
    
        originalTags.forEach(({ id }) => {
            if (!tags.some((tag) => tag.id === id)) {
                tagsToDelete.push(id);
            }
        });
    
        return Promise.allSettled([
            ...tagsToCreate.map(name =>
                createTag({
                    variables: { activityId, name },
                })
            ),
            ...tagsToDelete.map(id =>
                deleteTag({
                    variables: { id },
                })
            ),
        ]).catch((error) => {
            console.error(error);
        });
    }    
    function save() {
        if (!activityType || !name || !description) {
            setFormError(true);
            return;
        }
        setFormError(false);
        setSaving(true);
        const formData = new FormData();
        formData.append('id', id);
        formData.append('name', name);
        formData.append('description', description);
        formData.append('activity_type', activityType);
        formData.append('search_terms', searchTerms);
        formData.append('image', image);
        fetch(`${domain}/api/activities/${activity ? 'update' : 'create'}`, {
            method: 'POST',
            credentials: 'include',
            body: formData
        }).then(response => {
            setSaving(false);

            if (response.status === 200) {
                response.json().then(async data => {
                    await saveTags(data.id);
                    navigate(`/activities/${data.id}`);
                }).catch(err => {
                    setError(true);
                    setTimeout(() => setError(false), 2000);
                });
            }
            else if (response.status === 401 || response.status === 403) {
                db.user.update(1, { loggedIn: false });
                navigate('/login', { state: { returnToPage: true }});
            }
            else {
                setError(true);
                setTimeout(() => setError(false), 2000);
            }
        }).catch(err => {
            setError(true);
            setSaving(false);
            setTimeout(() => setError(false), 2000);
        });
    }
    React.useEffect(() => {
        if (activity) {
            setId(activity.id);
            setName(activity.name);
            setDescription(activity.description);
            setActivityType(activity.activityType);
            setSearchTerms(activity.searchTerms);
            setOriginalTags(activity.ocgTTagsByActivityId.nodes || []);
            setTags(activity.ocgTTagsByActivityId.nodes || []);
        }
    }, [activity]);
    React.useEffect(() => {
        if (getActivityError?.networkError?.statusCode === 401) {
            navigate('/login', { state: { returnToPage: true }});
        }
    }, [getActivityError, navigate]);
    React.useEffect(() => {
        db.appbar.put({ key: 1, title: params.id ? `Editing: ${activity?.name}` : 'New Activity' });
    }, [params, activity]);
    return (
        <React.Fragment>
            <Breadcrumbs sx={breadcrumbStyles} aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/activities">Activities</Link>
                <Typography color="text.primary">{activity ? 'Edit' : 'Add'}</Typography>
            </Breadcrumbs>
            <div style={{maxWidth: '1100px', marginTop: '2rem'}}>
                <TextField style={textFieldStyles} select label='ActivityType' color="primary" required
                    value={activityTypes ? activityType || '' : ''} onChange={(e) => setActivityType(e.target.value)} error={formError && !activityType ? true : false}>
                        {activityTypes?.map((activityType, index) => (
                            <MenuItem key={index} value={activityType}>{activityType}</MenuItem>
                        )) || []}
                </TextField>
                <TextField style={textFieldStyles} label="Name" color="primary" required 
                        error={formError && !name ? true : false} value={name || ''} onChange={(e) => setName(e.target.value)} />
                <TextField style={textFieldStyles} label="Description" color="primary" multiline required 
                    error={formError && !description ? true : false} value={description || ''} onChange={(e) => setDescription(e.target.value)} />
                <div style={{position:'relative', maxWidth: '600px'}}>
                    {activity ?
                        <Autocomplete
                            style={textFieldStyles}
                            multiple
                            freeSolo
                            options={[]}
                            inputValue={tagsInputValue}
                            onInputChange={(e, newInputValue) => setTagsInputValue(newInputValue)}
                            value={tags}
                            onChange={(e, newTags) => setTags(newTags)}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip label={option.name || option} {...getTagProps({ index })} style={{borderRadius: '4px'}} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search Terms"
                                    style={{width:'100%'}}
                                    variant="outlined"
                                    placeholder="Add search terms..."
                                />
                            )}
                        />
                    : null }
                </div>
                <TextField style={textFieldStyles} label="Image" color="primary" type="file"
                        InputLabelProps={{ shrink: true }} inputProps={{accept: "image/*" }} 
                        onChange={(e) => setImage(e.target.files[0])} />
                { activity ?
                    <FormControlLabel style={textFieldStyles} label="Include my name in search results" 
                        control={<Checkbox onChange={toggleNameInSearchTerms} checked={searchTerms?.includes(activity?.ocgTClinicianByClinicianId?.name)} />} />
                : null }
                <SaveButton saving={saving} error={error} variant='contained' size='medium' save={save} />
            </div>
        </React.Fragment>
    )
}